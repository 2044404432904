export enum ConvertPriceFormat {
  USD = 'USD',
  BINE = 'BINE',
}

function getPriceRate(format: ConvertPriceFormat) {
  switch (format) {
    case ConvertPriceFormat.USD:
      return 1
    case ConvertPriceFormat.BINE:
      return 0.1 // TODO: This should be loaded from DEX in the future
  }
}

export function convertPrice(price: number | string | bigint | null, format: ConvertPriceFormat): number | undefined {
  if (!price) {
    return undefined
  }

  price = Number(BigInt(price) / BigInt('1000000000000000000'))
  return price * getPriceRate(format)
}
