import { useMemo } from 'react'
import { environment } from '../../environments/environment'
import { MainPageQuery } from '@data-access'
import { convertPrice, ConvertPriceFormat } from '../helpers/convertPrice'

const { BINE_RESOURCE_URL } = environment

export function useCollections(rawCollections: MainPageQuery['collections']) {
  return useMemo(
    () =>
      rawCollections
        .map(collection => ({
          name: collection.chest.name,
          gameName: collection.chest.name,
          items: [
            {
              id: collection.chest.id,
              name: 'Collection Chest',
              bgSrc:
                collection.chest.image &&
                BINE_RESOURCE_URL + collection.chest.image,
              bgColor: collection.chest.additional?.rarity_color,
              price: convertPrice(
                collection.chest.price,
                ConvertPriceFormat.BINE,
              ),
              priceUsd: convertPrice(
                collection.chest.price,
                ConvertPriceFormat.USD,
              ),
              href: `/collection/${collection.chest.id}`,
            },
            ...collection.rarities.map(rarity => {
              const item = rarity.itemsData[0].item
              return {
                id: item.id,
                name: item.name,
                bgSrc: item.image && BINE_RESOURCE_URL + item.image,
                bgColor: item.additional?.rarity_color,
                price: convertPrice(item.price, ConvertPriceFormat.BINE),
                priceUsd: convertPrice(item.price, ConvertPriceFormat.USD),
                href: `/collection/${collection.chest.id}`,
              }
            }),
          ],
        }))
        .filter(collection => collection.items.length > 0),
    [rawCollections],
  )
}
