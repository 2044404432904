import { useMemo } from 'react'
import { ItemTransfersQuery } from '@data-access'
import { environment } from '../../environments/environment'
import { Activity } from '@bine-frontend-monorepo/uikit'
import { useContract } from './useContract'

const { BINE_RESOURCE_URL } = environment

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export function useItemTransfers(itemTransfers?: ItemTransfersQuery['itemTransfers']): Activity[] {
  const { contract: collectionsContract } = useContract('BineCollections')
  const { contract: marketContract } = useContract('BineMarket')

  return useMemo(
    () =>
      itemTransfers &&
      itemTransfers.map(transfer => ({
        item: {
          id: transfer.item.id,
          name: transfer.item.name,
          collectionId: transfer.item.boundCollection?.chest.id,
          collectionName: transfer.item.boundCollection?.chest.name,
          image: transfer.item.image && `${BINE_RESOURCE_URL}${transfer.item.image}`,
        },
        event: {
          type:
            transfer.from.id === ZERO_ADDRESS
              ? 'minted'
              : transfer.to.id === ZERO_ADDRESS
              ? 'burnt'
              : [transfer.to.id, transfer.from.id].includes(collectionsContract?.address.toLowerCase())
              ? 'opened'
              : transfer.to.id === marketContract?.address.toLowerCase()
              ? 'listed'
              : transfer.from.id === marketContract?.address.toLowerCase()
              ? 'unlisted'
              : 'transfered',
          amount: BigInt(transfer.amount),
        },
        price: {
          native: 100,
          usd: 300,
        },
        from: {
          id: transfer.from.id,
          name: transfer.from.nickname,
          image: transfer.from.image && `${BINE_RESOURCE_URL}${transfer.from.image}`,
        },
        to: {
          id: transfer.to.id,
          name: transfer.to.nickname,
          image: transfer.to.image && `${BINE_RESOURCE_URL}${transfer.to.image}`,
        },
        date: new Date(parseInt(transfer.chainCreatedTimestamp) * 1000),
        href: `/item/${transfer.item.id}`,
      })),
    [itemTransfers, marketContract, collectionsContract],
  )
}
