import { ReactNode, useEffect, useState } from 'react'
import { Footer, PageContainer, Header } from '@bine-frontend-monorepo/uikit'
import { useWeb3Auth } from '../hooks'
import { useContract } from '../hooks'
import { BigNumber } from 'ethers'
import { formatBalance } from '../helpers/formatBalance'
import { ConnectButton } from '@rainbow-me/rainbowkit'

export const DefaultPageLayout = ({ children }: { children: ReactNode }) => {
  const { isLoading, currentUser, logout, login } = useWeb3Auth()
  const { contract } = useContract('BineToken')
  const [balance, setBalance] = useState<BigNumber | undefined>(undefined)

  // useEffect(() => {
  //   if (contract && currentUser) {
  //     const fetchBalance = async () => {
  //       try {
  //         const balance = (await contract.functions.balanceOf(currentUser.id))[0] as BigNumber
  //         setBalance(balance)
  //       } catch (err) {
  //         console.log(err)
  //       }
  //     }

  //     fetchBalance()
  //   }
  // }, [contract, currentUser])

  return (
    <>
      <Header
        profile={{
          onConnectWallet: login,
          onLogout: logout,
          account: currentUser && { ...currentUser, balance: balance && formatBalance(balance) },
          isLoading,
        }}
      />
      <PageContainer>{children}</PageContainer>
      <Footer />
    </>
  )
}
