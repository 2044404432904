import { environment } from '../../environments/environment'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'

const abiNameList = ['BineCollections', 'BineCrowdsale', 'BineItems', 'BineMarket', 'BineToken', 'BineTrading'] as const

export type AbiNameListEntry = (typeof abiNameList)[number]
export type AbisType = Record<AbiNameListEntry, Record<string, unknown>>

export class AbiService {
  public async getAbi(abiName: AbiNameListEntry): Promise<Record<string, unknown> | null> {
    try {
      const abiUrl = `${environment.BINE_ABI_URL}${abiName}.json`
      const response = await fetch(abiUrl)
      const abi = await response.json()

      return abi
    } catch (error) {
      console.error(`Failed to get ABI for ${abiName}:`, error)
      return null
    }
  }

  public async getAbis(): Promise<AbisType> {
    const abis = {} as AbisType
    try {
      await Promise.all(
        abiNameList.map(async abiName => {
          const abi = await this.getAbi(abiName)
          if (abi) {
            abis[abiName] = abi
          }
        }),
      )

      return abis
    } catch (error) {
      console.error('Failed to get ABIs:', error)
      return abis
    }
  }

  public createContractFromAbi(abiObject: Record<string, any>, provider?: JsonRpcProvider | FallbackProvider) {
    const contractInterface = new utils.Interface(abiObject.abi)
    const contractAddress = abiObject.networks[environment.chainId]?.address

    if (!contractAddress) {
      return null
    }

    const contract = new Contract(contractAddress, contractInterface, provider)
    return contract
  }

  public async getContract(abiName: AbiNameListEntry, provider?: JsonRpcProvider | FallbackProvider) {
    const abi = await this.getAbi(abiName)

    if (!abi) return null

    const contract = this.createContractFromAbi(abi, provider)
    return contract
  }
}
