import { useState } from 'react'
import { ExploreItemsQueryParams } from '@bine-frontend-monorepo/uikit'
import { Item_OrderBy, Item_Filter } from '@data-access'

type QueryParams = {
  orderBy?: Item_OrderBy
  where?: Item_Filter
}

type UseItemQueryParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeQueryParams: (params: QueryParams) => Promise<any>
  defaultFilters?: Item_Filter
}

export const useItemQueryParams = ({ onChangeQueryParams, defaultFilters = {} }: UseItemQueryParams) => {
  const [queryParams, _setQueryParams] = useState<QueryParams>({
    where: defaultFilters,
  })
  const [refetching, setRefetching] = useState(false)
  const [queryParamsChanged, setQueryParamsChanged] = useState(false)

  const setQueryParams = async (params: ExploreItemsQueryParams) => {
    const tags = (params.filter.filters?.['type'].value as string[]) ?? []
    const rarities = (params.filter.filters?.['rarity'].value as string[]) ?? []
    const amounts = (params.filter.filters?.['amount'].value as string[]) ?? []
    const availability = (params.filter.filters?.['availability'].value as string[]) ?? []

    const calculatedQueryParams = {
      orderBy: {
        recently_added: Item_OrderBy.ChainCreatedTimestamp,
        price: Item_OrderBy.Price,
      }[params.sort],
      where: {
        tag_in: tags,
        rarity_in: rarities.map(
          rarity => ({ Common: 0, Uncommon: 1, Rare: 2, Epic: 3, Legendary: 4, Mythical: 5 }[rarity]),
        ),
        limited_in: amounts.map(rarity => ({ Limited: true, Unlimited: false }[rarity])),
        ...(availability.length === 2 ? {} : availability.includes('For Sale') ? { price_not: null } : { price: null }),
        ...defaultFilters,
      },
    }

    setRefetching(true)
    setQueryParamsChanged(true)
    _setQueryParams(calculatedQueryParams)
    try {
      await onChangeQueryParams(calculatedQueryParams)
    } finally {
      setRefetching(false)
    }
  }

  return {
    queryParams,
    setQueryParams,
    queryParamsChanged,
    refetching,
  }
}
