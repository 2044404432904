import { useMemo } from 'react'
import { environment } from '../../environments/environment'
import { ProfileQuery } from '@data-access'
import { convertPrice, ConvertPriceFormat } from '../helpers/convertPrice'

const { BINE_RESOURCE_URL } = environment

export function useProfile(profile?: ProfileQuery['user']) {
  return useMemo(
    () =>
      profile && {
        ...profile,
        image: profile.image && BINE_RESOURCE_URL + profile.image,
        friendsCount: 123,
        itemsCount: profile.itemsCount,
        totalSpent: 321,
        items: profile.items.map(affiliation => ({
          ...affiliation.item,
          owners: [
            {
              amount: affiliation.amount,
            },
          ],
        })),
      },
    [profile],
  )
}
