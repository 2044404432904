import { useMemo } from 'react'
import { environment } from '../../environments/environment'
import { GridItemFragment } from '@data-access'
import { convertPrice, ConvertPriceFormat } from '../helpers/convertPrice'

const { BINE_RESOURCE_URL } = environment

type OwnedGridItemFragment = GridItemFragment & {
  owners: {
    amount: number
  }[]
}

export function useExploreItems(itemFragments: (GridItemFragment | OwnedGridItemFragment)[]) {
  return useMemo(
    () =>
      itemFragments.map(item => ({
        id: item.id,
        title: item.name,
        gameTitle: item.boundCollection?.chest.name ?? 'Bine',
        bgSrc: item.image && BINE_RESOURCE_URL + item.image,
        bgColor: item.additional?.rarity_color,
        price: convertPrice(item.price, ConvertPriceFormat.BINE),
        priceUsd: convertPrice(item.price, ConvertPriceFormat.USD),
        href: `/item/${item.id}`,
        amount: 'owners' in item ? item.owners?.[0]?.amount : undefined,
        limited: item.limited,
        tag: item.tag as 'NFT' | 'FT',
      })),
    [itemFragments],
  )
}
