import { useMemo } from 'react'
import { CollectionQuery } from '@data-access'
import { type CollectionInfoData } from '@bine-frontend-monorepo/uikit'
import { GridItemFragment } from '@data-access'
import { getImageUrl } from '../helpers/getImageUrl'

export function useCollection(
  collection?: CollectionQuery['collection'],
): CollectionInfoData & { items: GridItemFragment[] } {
  return useMemo(
    () =>
      collection && {
        id: collection.id,
        image: getImageUrl(collection.chest.image),
        title: collection.chest.name,
        gameName: 'Some Game',
        backgroundImage: getImageUrl(collection.backgroundImage),
        description: collection.description,
        chestsToGet: collection.chestsToGet,
        chestsTotal: collection.chestsTotal,
        lastBlockAvailable: collection.lastBlockAvailable,
        firstBlockAvailable: collection.firstBlockAvailable,
        keyPrice: collection.keyPrice,
        chainCreatedTimestamp: collection.chainCreatedTimestamp,
        chest: {
          id: collection.chest.id,
          name: collection.chest.name,
          image: getImageUrl(collection.chest.image),
          additional: collection.chest.additional,
        },
        items: collection.items,
      },
    [collection],
  )
}
