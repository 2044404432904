import { gql, useApolloClient } from '@apollo/client'
import { AbiNameListEntry, AbiService } from '../services/abi.service'
import { Contract } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'

const GET_CONTRACT = gql`
  query GetContract($name: String!) {
    contract(name: $name)
  }
`

export function useContract(name: AbiNameListEntry, provider?: JsonRpcProvider | FallbackProvider) {
  const client = useApolloClient()
  const cachedContract = client.readQuery({
    query: GET_CONTRACT,
    variables: { name },
  })?.contract
  const [contract, setContract] = useState<Contract>(cachedContract)
  const [loading, setLoading] = useState(!cachedContract)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (contract) return
    const fetchContract = async () => {
      setLoading(true)
      setError(null)
      try {
        const abiService = new AbiService()
        const contractData = await abiService.getContract(name, provider)
        client.writeQuery({
          query: GET_CONTRACT,
          variables: { name },
          data: { contract: contractData },
        })

        setContract(contractData)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }

    fetchContract()
  }, [client, name, contract, provider])

  return { contract, loading, error }
}

export function useContractLazy(name: AbiNameListEntry, provider: JsonRpcProvider | FallbackProvider) {
  const abiService = useMemo(() => new AbiService(), [])
  const client = useApolloClient()
  const cachedContract = client.readQuery({
    query: GET_CONTRACT,
    variables: { name },
  })?.contract
  const [contract, setContract] = useState<Contract>(cachedContract)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getContract = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      let data = client.readQuery({
        query: GET_CONTRACT,
        variables: { name },
      })

      if (!data) {
        const contractData = await abiService.getContract(name, provider)
        client.writeQuery({
          query: GET_CONTRACT,
          variables: { name },
          data: { contract: contractData },
        })
        data = { contract: contractData }
      }

      setContract(data.contract)
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }, [client, name, abiService, provider])

  return { contract, loading, error, getContract }
}
