import { BigNumber } from 'ethers'

export const formatBalance = (value: BigNumber, decimals = 18): string => {
  // Divide value by 10 ** decimals to get actual value
  const actualValue = value.div(BigNumber.from(10).pow(decimals))
  let actualValueFloat = parseFloat(actualValue.toString())

  if (actualValue.isZero()) {
    return '0'
  }

  if (actualValueFloat < 0.01) {
    return '> 0.01'
  }

  if (actualValueFloat >= 10000) {
    // for more than 4 digits, don't show decimals
    actualValueFloat = Math.floor(actualValueFloat)
  } else {
    // for less than 5 digits, limit to 2 decimal places
    actualValueFloat = Math.round((actualValueFloat + Number.EPSILON) * 100) / 100
  }

  // Convert number to string and replace every sequence of 3 digits with a space
  return actualValueFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
