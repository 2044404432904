import { useMemo } from 'react'
import { ItemHistoryQuery } from '@data-access'

export function useItemHistory(
  itemTransfers: ItemHistoryQuery['itemTransfers'],
) {
  return useMemo(
    () =>
      itemTransfers.map(transfer => ({
        id: transfer.id,
        timestamp: transfer.chainCreatedTimestamp,
        from: {
          id: transfer.from.id,
          name: transfer.from.nickname,
        },
        to: {
          id: transfer.to.id,
          name: transfer.to.nickname,
        },
        amount: transfer.amount,
      })),
    [itemTransfers],
  )
}
