import { useCallback, useEffect, useState } from 'react'
import { useGetAccessTokenMutation, useGetDataToSignLazyQuery, useCurrentUserLazyQuery } from '@data-access'
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi'
import { AuthService } from '../services/auth.service'
import { environment } from '../../environments/environment'
import { toast } from 'react-toastify'

const { BINE_RESOURCE_URL } = environment

type User = {
  id: string
  name?: string
  image?: string
}

export function useWeb3Auth() {
  const { address: account, isConnected: active } = useAccount()
  const { signMessage, data: signatureData } = useSignMessage()
  const { connect: connectWallet } = useConnect()
  const { disconnect: deactivate } = useDisconnect()
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [getDataToSign] = useGetDataToSignLazyQuery()
  const [getAccessTokenMutation] = useGetAccessTokenMutation()
  const [getCurrentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
  })

  const logout = useCallback(() => {
    deactivate()
    AuthService.removeTokenFromStorage()
    AuthService.removeUserFromStorage()
    setCurrentUser(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadUserData = useCallback(async () => {
    const user = await getCurrentUser({
      context: {
        headers: {
          Authorization: AuthService.getTokenFromStorage(),
        },
      },
    })
    if (user.data.currentUser.__typename === 'AuthError') {
      const errorMsg = 'An AuthError has occurred while fetching current user data'
      console.error(errorMsg)
      toast.error(errorMsg)
      logout()
      return
    }

    const userData = {
      id: user.data.currentUser.id,
      name: user.data.currentUser.nickname,
      image: user.data.currentUser.image && `${BINE_RESOURCE_URL}${user.data.currentUser.image}`,
    }

    setCurrentUser(userData)
    AuthService.storeUserToStorage(userData)
  }, [getCurrentUser, logout])

  const restoreSession = useCallback(async () => {
    const token = AuthService.getTokenFromStorage()
    if (!token) return

    await loadUserData()
  }, [loadUserData])

  const login = useCallback(async () => {
    setIsLoading(true)
    try {
      connectWallet()

      if (!account) return

      const signData = await getDataToSign({
        variables: { address: account },
      })

      const { message, domain, types } = signData.data.signData

      const domainWithoutSalt = { ...domain }
      delete domainWithoutSalt.salt

      signMessage({
        message: JSON.stringify({
          domain: domainWithoutSalt,
          types: { AuthMessage: types.AuthMessage },
          message,
        }),
      })

      const accessTokenData = await getAccessTokenMutation({
        variables: {
          address: message.address,
          signature: signatureData,
          noonce: message.noonce,
          salt: null,
        },
      })

      const getAccessTokenData = accessTokenData.data.getAccessToken
      if (getAccessTokenData.__typename === 'AuthError') {
        const errorMsg = getAccessTokenData.message
        console.error(errorMsg)
        toast.error(errorMsg)
        return
      } else {
        AuthService.storeTokenToStorage(getAccessTokenData.accessToken)
      }

      await loadUserData()
    } catch (error) {
      const errorMsg = 'message' in error ? error.message : error
      console.error(errorMsg)
      toast.error(errorMsg)
      logout()
    } finally {
      setIsLoading(false)
    }
  }, [connectWallet, account, getDataToSign, signMessage, getAccessTokenMutation, signatureData, loadUserData, logout])

  useEffect(() => {
    if (active && AuthService.tokenExistsInStorage()) {
      if (AuthService.userExistsInStorage()) {
        setCurrentUser(AuthService.getUserFromStorage())
        loadUserData()
        return
      }
      setIsLoading(false)
    }
  }, [active, restoreSession, setIsLoading, loadUserData])

  return {
    isLoading,
    currentUser,
    login,
    logout,
    connectWallet,
  } as const
}
