import { useMemo } from 'react'
import { environment } from '../../environments/environment'
import { ItemQuery } from '@data-access'
import { convertPrice, ConvertPriceFormat } from '../helpers/convertPrice'

const { BINE_RESOURCE_URL } = environment

export function useItem(item: ItemQuery['item']) {
  return useMemo(
    () =>
      item && {
        id: item.id,
        name: item.name,
        description: item.description,
        imageHref: item.image && BINE_RESOURCE_URL + item.image,
        bgColor: item.additional?.rarity_color,
        price: convertPrice(item.price, ConvertPriceFormat.BINE),
        priceUsd: convertPrice(item.price, ConvertPriceFormat.USD),
        additional: item.additional,
        tag: item.tag as 'NFT' | 'FT',
        limited: item.limited,
        ownersCount: item.ownersCount,
        owner: item.owners[0] && {
          id: item.owners[0].owner.id,
          nickname: item.owners[0].owner.nickname,
          href: `/profile/${item.owners[0].owner.id}`,
        },
        collection: {
          id: item.boundCollection?.chest.id,
          name: item.boundCollection?.chest.name,
          imageHref: item.boundCollection?.chest.image && BINE_RESOURCE_URL + item.boundCollection?.chest.image,
        },
      },
    [item],
  )
}
