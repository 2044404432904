import { useMemo } from 'react'
import { environment } from '../../environments/environment'
import { ItemQuery } from '@data-access'
import { convertPrice, ConvertPriceFormat } from '../helpers/convertPrice'

const { BINE_RESOURCE_URL } = environment

export function useRelatedItems(item: ItemQuery['item']) {
  return useMemo(
    () =>
      item?.boundCollection?.rarities.reduce(
        (acc, rarity) => [
          ...acc,
          ...rarity.itemsData.map(itemData => ({
            id: itemData.item.id,
            bgSrc:
              itemData.item.image &&
              `${BINE_RESOURCE_URL}${itemData.item.image}`,
            bgColor: itemData.item.additional?.rarity_color,
            title: itemData.item.name,
            gameTitle: 'Some Game',
            price: convertPrice(itemData.item.price, ConvertPriceFormat.BINE),
            priceUsd: convertPrice(itemData.item.price, ConvertPriceFormat.USD),
            href: `/item/${itemData.item.id}`,
          })),
        ],
        [],
      ),
    [item],
  )
}
